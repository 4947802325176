import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MODAL_DATA} from '@modules/modal/classes/modal-data';

import {NotificationData, NotificationType} from '../../models/notification.model';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent implements OnInit {

  readonly notificationTypes = NotificationType;
  constructor(@Inject(MODAL_DATA) public readonly data: NotificationData) {
  }

  ngOnInit() {
  }

}
