import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {TranslocoModule} from '@ngneat/transloco';

import {PlatformBrowserService} from '@core/modules';

import {NotificationComponent} from './components/notification-container/notification.component';
import {NotificationService} from './services/notification/notification.service';

import {ModalModule, OverlayService, SEAZ_MODAL_CONFIG} from '../modal';
import {
  errorCircleIcon,
  mascotOkIcon,
  SeazoneIconRegistryService,
  SeazoneIconsModule,
  successCircleIcon,
  warningTriangleIcon,
} from '../seazone-icons';

export function NotificationServiceFactory(overlayService: OverlayService, platformBrowserService: PlatformBrowserService):
NotificationService {
  return new NotificationService(overlayService, platformBrowserService);
}

@NgModule({
  imports: [
    CommonModule,
    ModalModule,
    SeazoneIconsModule,
    TranslocoModule,
  ],
  providers: [
    {
      provide: SEAZ_MODAL_CONFIG,
      useValue: {},
    },
  ],
  declarations: [NotificationComponent],
})
export class NotificationModule {

  constructor(private readonly seazoneIconRegistryService: SeazoneIconRegistryService) {
    this.seazoneIconRegistryService.registerIcons([successCircleIcon, errorCircleIcon, mascotOkIcon, warningTriangleIcon]);
  }

  static forRoot(): ModuleWithProviders<NotificationModule> {
    return {
      ngModule: NotificationModule,
      providers: [
        {
          provide: NotificationService,
          useFactory: NotificationServiceFactory,
          deps: [OverlayService, PlatformBrowserService],
        },
      ],
    };
  }
}
