<div class="notification-modal-content">
  <div class="notification-modal-content__img">
    <ng-container [ngSwitch]="data?.type">
      <app-seazone-icon *ngSwitchCase="notificationTypes.SUCCESS" name="successCircle"> </app-seazone-icon>
      <app-seazone-icon *ngSwitchCase="notificationTypes.ERROR" name="errorCircle"> </app-seazone-icon>
      <app-seazone-icon *ngSwitchCase="notificationTypes.WARNING" name="warningTriangle"> </app-seazone-icon>
      <app-seazone-icon *ngSwitchCase="notificationTypes.INFO" name="mascotOk" [width]="125" [height]="125">
      </app-seazone-icon>
    </ng-container>
  </div>
  <div
    class="notification-modal-content__text"
    [innerHTML]="data?.translate ? (data?.message | transloco) : data?.message"></div>
</div>
