import {Injectable} from '@angular/core';
import {OverlayCloseEvent} from '@modules/modal/interfaces/overlay-close-event';
import {NotificationData, NotificationType} from '@modules/notification/models/notification.model';
import {EMPTY, Observable} from 'rxjs';
import {take} from 'rxjs/operators';

import {PlatformBrowserService} from '@core/modules';
import {ResponseDto} from '@models/response';

import {CustomOverlayRef, OverlayService} from '../../../modal';
import {NotificationComponent} from '../../components/notification-container/notification.component';

let service: NotificationService;

export function showHttpNotificationMessage<T extends ResponseDto = ResponseDto>(res: T): T {
  if (!res) {
    return res;
  }
  const {message, success} = res;
  if (!message) {
    return res;
  }
  success ? service.success(message) : service.error(message);
  return res;
}

@Injectable()
export class NotificationService {

  private readonly isBrowser: boolean;
  private overlayRef: CustomOverlayRef | null;

  constructor(
    private readonly overlayService: OverlayService,
    private readonly platformBrowserService: PlatformBrowserService,
  ) {
    service = this;
    this.isBrowser = this.platformBrowserService.isBrowser;
  }

  info(message: string, timeout: number | null = 3000): Observable<OverlayCloseEvent<null>> {
    return this.openModal(message, NotificationType.INFO, timeout);
  }

  error(message: string, timeout: number | null = 3000, preventBackdropClick = false): Observable<OverlayCloseEvent<null>> {
    return this.openModal(message, NotificationType.ERROR, timeout, false, preventBackdropClick);
  }

  success(message: string, timeout: number | null = 3000): Observable<OverlayCloseEvent<null>> {
    return this.openModal(message, NotificationType.SUCCESS, timeout);
  }

  warning(message: string, timeout: number | null = 3000): Observable<OverlayCloseEvent<null>> {
    return this.openModal(message, NotificationType.WARNING, timeout);
  }

  openByType(message: string, type: NotificationType, timeout: number | null = 3000, translate = false)
    : Observable<OverlayCloseEvent<null>> {
    return this.openModal(message, type, timeout, translate);
  }

  private openModal(
    message: string,
    type: NotificationType,
    timeout: number | null = 3000,
    translate = false,
    preventBackdropClick = false,
  )
    : Observable<OverlayCloseEvent<null>> {
    if (!this.isBrowser || this.overlayRef) {
      return EMPTY;
    }
    const data = new NotificationData(type, message, translate);
    this.overlayRef = this.overlayService.open(
      NotificationComponent,
      data,
      {disposeOnNavigation: false},
      {preventBackdropClick},
    );
    if (timeout) {
      setTimeout(() => {
        const ref = this.overlayRef;
        if (ref) {
          ref.close(null);
        }
      }, timeout);
    }
    if (!this.overlayRef) {
      return EMPTY;
    }
    const closed$ = this.overlayRef.afterClosed$;
    closed$
      .pipe(take(1))
      .subscribe(() => this.overlayRef = null);
    return closed$;
  }

}
